import authModule from '@/store/auth'
import { Component, Vue } from 'vue-property-decorator'

// import BreadCrumb from '@/components/BreadCrumbs/BreadCrumbs.vue'
@Component({
  components: {},
})
export default class Navbar extends Vue {
  get is_login(): boolean {
    return authModule.isAuthenticated
  }
}
